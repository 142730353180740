<template>
  <div id="app">
    <router-view />
  </div>
</template>
<script>
import { mapState } from 'vuex'
export default {
  data() {
    return {
      notDArkPath: ['/', '/login'] //不需要暗黑模式的路由统一设置
    }
  },
  computed: {
    ...mapState(['isDark'])
  },
  created() {
    let isDark = JSON.parse(window.localStorage.getItem('isDark'))
    // console.log(isDark)
    if (isDark !== null) {
      this.$store.commit('setDark', isDark)
    }
  },
  watch: {
    '$route.path': function (newPath, oldPath) {
      if (this.notDArkPath.includes(newPath) && this.isDark) {
        document.getElementsByTagName('html')[0].classList.remove('darkly_mode')
      }
      if (!this.notDArkPath.includes(newPath) && this.isDark) {
        document.getElementsByTagName('html')[0].classList.add('darkly_mode')
      }
    },
    isDark(val) {
      // console.log('anhei', val)
      if (val) {
        window.localStorage.setItem('isDark', JSON.stringify(true))
        if (!this.notDArkPath.includes(this.$route.path)) {
          document.getElementsByTagName('html')[0].classList.add('darkly_mode')
        }
      } else {
        document.getElementsByTagName('html')[0].classList.remove('darkly_mode')
        window.localStorage.setItem('isDark', JSON.stringify(false))
      }
    }
  }
}
</script>
<style lang="scss">
html {
  background-color: #fff;
  transition: all 0.5s;
}
* {
  margin: 0;
  padding: 0;
  outline: none;
  box-sizing: border-box;
}
html,
body {
  height: 100%;
}
#app {
  height: 100%;
  font-family: Avenir, Helvetica, Arial, sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  color: #2c3e50;
}

nav {
  padding: 30px;

  a {
    font-weight: bold;
    color: #2c3e50;

    &.router-link-exact-active {
      color: #42b983;
    }
  }
}
</style>
