<template>
  <div>
    <div class="tab_box" v-if="parentData.showListCount || $scopedSlots.right">
      <div class="right_slot" :class="!parentData.showListCount ? 'noTab' : ''">
        <slot name="right"></slot>
      </div>
      <el-tabs ref="tabs-container" v-if="parentData.showListCount" v-model="parentData[tabKey]" @tab-click="parentData.tabClick" class="tabs-container">
        <el-tab-pane v-for="item in listCount ? listCount : parentData.listCount" :key="item.name" :label="parentData.showListCountTotal ? `${item.name} (${item.num})` : `${item.name}`" :name="item.type" />
      </el-tabs>
    </div>
    <el-table :ref="tableRef" v-bind="bind" v-on="$listeners" v-yplLoading="loading !== undefined ? loading : parentData.loading" :data="data ? data : parentData.list" highlight-current-row :border="border" :show-summary="parentData.showSummary" :summary-method="parentData.getSummaries" @expand-change="expandChange" @row-dblclick="rowDblClick" @row-click="rowClick" @selection-change="selectChange" @sort-change="sortChange" :default-sort="parentData.defaultSort && parentData.defaultSort()">
      <slot />
    </el-table>
    <ypl-pagination v-if="parentData.showPagination && parentData.total > 0" :total="parentData.total" :current-page.sync="parentData.pageForm.current" :page-size.sync="parentData.pageForm.size" @size-change="parentData.getList()" @current-change="parentData.getList()" />
  </div>
</template>

<script>
var elementResizeDetectorMaker = require('element-resize-detector')
export default {
  name: 'YplTable',
  props: {
    tabKey: {
      type: String,
      default: 'status'
    },
    parent: {
      type: Object
    },
    border: {
      type: Boolean,
      default: true
    },
    height: {
      type: Number,
      default: 0
    },
    autoHeight: {
      type: Boolean,
      default: false
    },
    data: {
      type: Array
    },
    loading: {},
    tableRef: {
      type: String,
      default: 'table-container'
    },
    listCount: {
      type: Array
    }
  },
  data() {
    return {
      tableHeight: 200
    }
  },
  methods: {
    //获取dom相对位置（包含滚动条）
    getpos(e) {
      var t = e.offsetTop
      // console.log(e.offsetParent)
      if (e.offsetParent) {
        t += this.getpos(e.offsetParent)
      }
      return t
    },
    resetTableHeight(obj) {
      if (this.height || this.autoHeight) {
        return
      }
      //重新设置table的高度
      this.$nextTick(function () {
        if (this.parentData.isDialog) {
          let bottomH = 50
          if (obj && obj.fullscreen) {
            bottomH = 0
          }
          if (this.parentData.type == 'page') {
            bottomH = 10
          }
          // this.$refs['table-container'].$el.getBoundingClientRect().y  //（不包含滚动条，弹窗滚动影响结果）
          // console.log(this.parentData.isView)
          let h = document.documentElement.clientHeight - bottomH - this.getpos(this.$refs[this.tableRef].$el) - (this.parentData.isView ? 30 : 96) - (this.parentData.showPagination ? 38 : 0)
          // console.log(h)
          this.tableHeight = h > 180 ? h : 180
        } else {
          //减去分页插件的高度38 documentElement padding_bottom 10px
          this.tableHeight = document.documentElement.clientHeight - this.getpos(this.$refs[this.tableRef].$el) - (this.parentData.showPagination ? 48 : 10)
        }
      })
    },
    selectChange(selection) {
      this.parentData.handleSelectionChange && this.parentData.handleSelectionChange(selection)
    },
    sortChange(data) {
      this.parentData.sortChange && this.parentData.sortChange(data)
    },
    rowClick(row, column, event) {
      this.parentData.rowClick && this.parentData.rowClick(row, column, event)
    },
    rowDblClick(row, column, event) {
      this.parentData.rowDblClick && this.parentData.rowDblClick(row, column, event)
    },
    expandChange(row, expandedRows) {
      this.parentData.expandChange && this.parentData.expandChange(row, expandedRows)
    }
  },
  mounted() {
    let doc = document.getElementsByClassName('mainContainer')[0]
    let formDoc = document.getElementById('form-container')
    console.log(formDoc)
    if (!this.parentData.isDialog && formDoc) {
      //监听table上方的尺寸变化，动态改变table的高度
      var erd = elementResizeDetectorMaker()
      let time = 0
      erd.listenTo(formDoc, () => {
        doc.style = 'height: 100%;overflow-y: hidden;'
        time += 1
        let newTime = time
        setTimeout(() => {
          if (newTime == time) {
            this.resetTableHeight()
            time = 0
            setTimeout(() => {
              doc.style = ''
            }, 1000)
          }
        }, 50)
      })
      // 监听table 滚动条
      // let tableBodyDom = this.$refs['table-container'].bodyWrapper
      // tableBodyDom.addEventListener('scroll', () => {
      //   if (tableBodyDom.scrollTop == 0) {
      //     if (!this.parentData.loading) {
      //       doc.style = 'height: 100%;overflow-y: hidden;'
      //       this.$nextTick(function () {
      //         this.parentData.showSearch = true
      //       })
      //     }
      //   } else {
      //     if (this.parentData.showSearch) {
      //       if (!this.parentData.loading && tableBodyDom.scrollHeight > this.$refs['table-container'].$el.getBoundingClientRect().y - 140 + tableBodyDom.clientHeight) {
      //         this.$nextTick(function () {
      //           this.parentData.showSearch = false
      //         })
      //       }
      //     }
      //   }
      // })
    } else {
      this.resetTableHeight()
    }
  },
  computed: {
    parentData() {
      return this.parent || (this.$parent.$options.name == 'ElDialog' ? this.$parent.$parent.$parent : this.$parent.$options.name == 'ElForm' ? (this.$parent.$parent.$parent.$parent.type == 'page' ? this.$parent.$parent.$parent.$parent : this.$parent.$parent.$parent.$parent.$parent) : this.$parent)
    },
    bind() {
      let newObj = {
        ...this.$attrs
      }
      if (!this.autoHeight) {
        newObj.height = this.height ? this.height : this.tableHeight
      }
      return newObj
    }
  },
  watch: {
    '$store.state.windowSize': {
      handler: function (val) {
        this.resetTableHeight()
      },
      deep: true
    }
  }
}
</script>
<style lang="scss" scoped>
.tab_box {
  position: relative;
  .right_slot {
    position: absolute;
    right: 20px;
    height: 100%;
    padding-top: 10px;
    font-weight: bold;
    font-size: 13px;
    &.noTab {
      padding-top: 0;
      top: -20px;
    }
  }
  .tabs-container {
    ::v-deep .el-tabs__header {
      margin-bottom: 0px;
    }
  }
}
</style>
