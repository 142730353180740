import { getRequest, postRequest } from '@/utils/request'

export function getList(obj) {
  return postRequest('/sysWebLog/list', obj)
}
export function getRowById(id) {
  return getRequest(`/sysWebLog/getSysWebLogById?id=${id}`)
}
export function saveWebLog(obj) {
  return postRequest('/sysWebLog/saveAll', obj)
}
export function getWebFunctionList() {
  return getRequest('/sysWebLog/getWebFunctionList')
}
