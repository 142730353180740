<template>
  <el-descriptions-item v-bind="$attrs" v-on="$listeners">
    <slot></slot>
  </el-descriptions-item>
</template>

<script>
export default {
  name: 'YplDescriptionsItem',
  props: {},
  computed: {},
  mounted() {
    console.log(666)
    console.log(this.$slots)
    console.log(this.$scopedSlots.default())
  }
}
</script>
