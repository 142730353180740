<template>
  <el-table-column v-bind="$attrs" v-on="$listeners">
    <template v-if="$scopedSlots.default || viewList || isAmount" #default="scope">
      <slot v-if="$scopedSlots.default" v-bind="scope"></slot>
      <div v-else-if="isAmount">{{ scope.row[scope.column.property]?.splitAmount() }}</div>
      <div v-else>{{ parentData.dictionaryText && parentData.dictionaryText(viewList, scope.row[scope.column.property], viewOption) }}</div>
    </template>
  </el-table-column>
</template>

<script>
export default {
  name: 'YplTableColumn',
  props: {
    //列表返显
    viewList: {
      type: Array
    },
    viewOption: {
      type: Object
    },
    //金额格式化
    isAmount: {
      type: Boolean,
      default: false
    }
  },
  computed: {
    parentData() {
      return this.$parent.$parent.$parent.$options.name == 'ElDialog' ? this.$parent.$parent.$parent.$parent.$parent : this.$parent.$parent.$parent
    }
  }
}
</script>
