<template>
  <el-form ref="form" :model="parentData.form" :rules="parentData.rules" label-position="top" :disabled="parentData.option && parentData.option.disabled">
    <slot />
  </el-form>
</template>

<script>
export default {
  name: 'YplDialogForm',
  props: {
    parent: {
      type: Object
    }
  },
  computed: {
    parentData() {
      return this.parent || (this.$parent.$parent.type == 'page' ? this.$parent.$parent : this.$parent.$parent.$parent)
    }
  }
}
</script>
