<template>
  <div class="app_index">
    <Menu></Menu>
    <div class="mainContainer" :class="!menuOpend ? 'hide_menu' : ''">
      <fixed-header></fixed-header>
      <div class="routerView">
        <!--transition可让list.mixin deactivated记录滚动距离-->
        <transition mode="out-in">
          <keep-alive :include="includeList">
            <router-view :key="key" />
          </keep-alive>
        </transition>
      </div>
    </div>
  </div>
</template>
<script>
import { mapState } from 'vuex'
import Menu from './menu'
import fixedHeader from './fixedHeader'
export default {
  components: {
    Menu,
    fixedHeader
  },
  computed: {
    ...mapState(['menuOpend', 'visitedRoutesList']),
    key() {
      return this.$route.path
    },
    includeList() {
      console.log(this.visitedRoutesList.map(one => one.name))
      return this.visitedRoutesList.map(one => one.name)
    }
  },
  beforeMount() {
    window.addEventListener('resize', this.resizeHandler)
  },
  beforeDestroy() {
    window.removeEventListener('resize', this.resizeHandler)
  },
  methods: {
    resizeHandler() {
      this.$store.commit('setWindowSize', {
        width: document.documentElement.clientWidth,
        height: document.documentElement.clientHeight
      })
    }
  }
}
</script>
<style lang="scss" scoped>
.app_index {
  height: 100%;
  .mainContainer {
    min-height: 100%;
    transition: margin-left 0.28s;
    margin-left: 200px;
    &.hide_menu {
      margin-left: 54px;
    }
    .routerView {
      padding: 87px 20px 10px;
    }
  }
}
</style>
