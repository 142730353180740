//数字三位数分割
export function splitAmount() {
  return this.toString().replace(/\d+/, n => {
    return n.replace(/(\d)(?=(?:\d{3})+$)/g, '$1,')
  })
}

//四舍五入保留2位小数（若第二位小数为0，则保留一位小数）
export function keepTwoDecimal(num, unit) {
  //unit:10000,转为万元 1000，转为千元
  var result = parseFloat(num)
  if (isNaN(result)) {
    num = 0
  }
  result = Math.round((num / (unit || 1)) * 100) / 100
  return result
}

Number.prototype.splitAmount = splitAmount
String.prototype.splitAmount = splitAmount

//日期格式化
Date.prototype.format = function (fmt) {
  var o = {
    'M+': this.getMonth() + 1, //月份
    'd+': this.getDate(), //日
    'h+': this.getHours(), //小时
    'm+': this.getMinutes(), //分
    's+': this.getSeconds(), //秒
    'q+': Math.floor((this.getMonth() + 3) / 3), //季度
    S: this.getMilliseconds() //毫秒
  }
  if (/(y+)/.test(fmt)) {
    fmt = fmt.replace(RegExp.$1, (this.getFullYear() + '').substr(4 - RegExp.$1.length))
  }
  for (var k in o) {
    if (new RegExp('(' + k + ')').test(fmt)) {
      fmt = fmt.replace(RegExp.$1, RegExp.$1.length == 1 ? o[k] : ('00' + o[k]).substr(('' + o[k]).length))
    }
  }
  return fmt
}

export function intervalDateByYearWeek(year, week) {
  if (year && week > 0) {
    let yearFirstDate = new Date(year + '-01-01')
    let yearFirstDay = yearFirstDate.getDay()
    let oneDayTime = 24 * 60 * 60 * 1000
    let beginDate = new Date(yearFirstDate.getTime() + ((week - 1) * 7 - yearFirstDay) * oneDayTime)
    let endDate = new Date(yearFirstDate.getTime() + (week * 7 - yearFirstDay - 1) * oneDayTime)

    let maxWeek = maxWeekByYear(year)
    if (beginDate.getFullYear() == year - 1) {
      beginDate = new Date(year + '-01-01')
    }
    if (endDate.getFullYear() != year) {
      if (week <= maxWeek) {
        endDate = new Date(new Date(endDate.getFullYear() + '-01-01').getTime() - oneDayTime)
      } else {
        return null
      }
    }
    // console.log(beginDate)
    // console.log(endDate)
    return {
      beginDate: beginDate.format('yyyy-MM-dd'),
      endDate: endDate.format('yyyy-MM-dd')
    }
  } else {
    return null
  }
}
export function intervalYearWeekByDate(d) {
  if (d) {
    let date = new Date(d)
    let year = date.getFullYear()
    let yearFirstDate = new Date(year + '-01-01')
    let yearFirstDay = yearFirstDate.getDay()
    let oneDayTime = 24 * 60 * 60 * 1000
    let week = Math.ceil((date.getTime() - yearFirstDate.getTime() + yearFirstDay * oneDayTime + 1000) / (7 * oneDayTime)) //+1000是为了不让整除
    return {
      year,
      week
    }
  } else {
    return null
  }
}
export function maxWeekByYear(year) {
  if (year) {
    let oneDayTime = 24 * 60 * 60 * 1000
    return intervalYearWeekByDate(new Date(new Date(year * 1 + 1 + '-01-01').getTime() - oneDayTime).format('yyyy-MM-dd')).week
  } else {
    return 0
  }
}
