import { getRequest, postRequest } from '@/utils/request'

// 登录方法
export function login(obj) {
  let res = postRequest('/sysUser/login', obj)
  return res
}

//获取登录用户信息
export function getUserInfo() {
  let res = getRequest('/sysUser/getUserInfo')
  return res
}

//退出登录
export function logout() {
  let res = getRequest('/sysUser/logout')
  return res
}
// 企业微信登录方法
export function wxlogin(obj) {
  let res = postRequest('/sysUser/wxLogin', obj)
  return res
}
