<template>
  <div id="form-container">
    <div class="box" :style="`height:${formHeight}px`" :class="showAll ? '' : 'form_hidden'">
      <el-form ref="form_ref" class="form_box" v-bind="$attrs" v-on="$listeners" :model="parentData.queryForm" :inline="inline" label-position="top" size="small">
        <slot />
      </el-form>
    </div>
    <el-divider v-if="showDivider" class="form_divider"><i class="icon_box" :class="showAll ? 'el-icon-d-arrow-left' : 'el-icon-d-arrow-right'" @click="changeShow"></i></el-divider>
    <div class="form_btn">
      <el-button type="primary" plain icon="el-icon-search" size="mini" @click="parentData.handleQuery" :loading="parentData.loading">搜索</el-button>
      <el-button type="primary" plain icon="el-icon-refresh" size="mini" @click="parentData.resetQueryForm">重置</el-button>
      <slot name="buttons" />
    </div>
  </div>
</template>

<script>
var elementResizeDetectorMaker = require('element-resize-detector')
export default {
  name: 'YplForm',
  props: {
    parent: {
      type: Object
    },
    inline: {
      type: Boolean,
      default: true
    }
  },
  data() {
    return {
      showAll: true,
      showDivider: false,
      stopListen: false,
      itemHeight: 0,
      formHeight: 0
    }
  },
  computed: {
    parentData() {
      return this.parent || (this.$parent.$options.name == 'ElDialog' ? this.$parent.$parent.$parent : this.$parent)
    }
  },
  mounted() {
    var erd = elementResizeDetectorMaker()
    erd.listenTo(this.$refs.form_ref.$el, () => {
      if (this.$slots.default && !this.stopListen) {
        this.setHeight()
      }
    })
  },
  methods: {
    setHeight() {
      this.itemHeight = this.$slots.default
        .filter(item => item.elm && item.elm.offsetHeight)
        .map(one => one.elm.offsetHeight + 18)
        .sort((a, b) => b - a)[0]
      this.formHeight = this.$refs.form_ref.$el.offsetHeight
      if (this.itemHeight && this.formHeight > this.itemHeight) {
        this.showDivider = true
        this.showAll = false
      } else {
        this.showDivider = false
        this.showAll = true
      }
    },
    changeShow() {
      this.stopListen = true
      this.showAll = !this.showAll
      setTimeout(() => {
        this.stopListen = false
      }, 1000)
    }
  }
}
</script>
<style lang="scss" scoped>
.box {
  height: auto;
  overflow-y: hidden;
  transition: height 0.5s;
  &.form_hidden {
    height: 83px !important;
  }
}
.icon_box {
  font-size: 22px;
  cursor: pointer;
  transform: rotate(90deg);
}
.form_divider {
  margin: 0 0 15px;
}
.form_btn {
  margin-left: -5px;
  margin-right: -5px;
  .el-button {
    margin: 0 5px 5px;
  }
}
</style>
