import { getRequest, postRequest } from '@/utils/request'

export function getList(obj) {
  return postRequest('/sysUser/list', obj)
}
export function getRowById(id) {
  return getRequest(`/sysUser/getSysUserById?id=${id}`)
}
export function add(obj) {
  return postRequest(`/sysUser/save`, obj)
}
export function edit(obj) {
  return postRequest(`/sysUser/update`, obj)
}
export function del(id) {
  return getRequest(`/sysUser/delete?id=${id}`)
}
export function disable(id) {
  return getRequest(`/sysUser/disable?id=${id}`)
}
export function enable(id) {
  return getRequest(`/sysUser/enable?id=${id}`)
}
export function getRoleIdsById(id) {
  return getRequest(`/sysUser/getRoleIdsById?id=${id}`)
}
//分配用户角色
export function addUserRoleList(obj) {
  return postRequest(`/sysUser/addUserRoleList`, obj)
}
//重置密码
export function resetPassword(id) {
  return getRequest(`/sysUser/resetPassword?id=${id}`)
}
//修改密码
export function updatePassword(obj) {
  return postRequest(`/sysUser/updatePassword`, obj)
}
//获取全部岗位信息
export function getAllSysPositionList() {
  return getRequest(`/sysUser/getAllSysPositionList`)
}
//获取全部岗位信息
export function getSysCompanyTree() {
  return getRequest(`/sysUser/getSysCompanyTree`)
}
//获取用户树
export function getSysUserTree() {
  return getRequest(`/sysUser/getSysUserTree`)
}
//获取销售列表
export function getSalesUserList(departmentId) {
  if (departmentId) {
    return getRequest(`/sysUser/getSalesUserList?departmentId=${departmentId}`)
  } else {
    return getRequest(`/sysUser/getSalesUserList`)
  }
}
//获取销售助理列表
export function getSalesAssistantUserList() {
  return getRequest(`/sysUser/getSalesAssistantUserList`)
}
//获取空运列表
export function getAirServiceUserList() {
  return getRequest(`/sysUser/getAirServiceUserList`)
}
//获取海运列表
export function getSeaServiceUserList() {
  return getRequest(`/sysUser/getSeaServiceUserList`)
}
//获取全部用户
export function getAllUserList() {
  return getRequest(`/sysUser/getAllUserList`)
}
//获取下级用户列表
export function getSubordinateUserList() {
  return getRequest(`/sysUser/getSubordinateUserList`)
}
//获取下级销售用户列表
export function getSubordinateSalesUserList() {
  return getRequest(`/sysUser/getSubordinateSalesUserList`)
}
