import Vue from 'vue'
import loading from './loadingImg.vue'
import { addClass, removeClass, getStyle } from 'element-ui/src/utils/dom'

const Mask = Vue.extend(loading)
const toggleLoading = (el, binding) => {
  if (binding.value) {
    Vue.nextTick(() => {
      addClass(el, 'elrelative')
      console.log(el.parentNode.className)
      //ypl-loading 弹窗加载时去掉滚动
      if (el.parentNode.className.includes('el-dialog__body')) {
        addClass(el.parentNode, 'dialog_body_hidden')
      }
      el.appendChild(el.mask)
      el.instance.setIntervalFun()
    })
  } else {
    removeClass(el, 'elrelative')
    removeClass(el.parentNode, 'dialog_body_hidden')
    el.mask && el.mask.parentNode && el.mask.parentNode.removeChild(el.mask)
    el.instance && el.instance.clearIntervalFun()
  }
}
//v-yplLoading
Vue.directive('yplLoading', {
  bind(el, binding) {
    const mask = new Mask({
      el: document.createElement('div')
    })
    el.instance = mask
    el.mask = mask.$el
    toggleLoading(el, binding)
  },
  update(el, binding) {
    if (binding.oldValue !== binding.value) {
      toggleLoading(el, binding)
    }
  },
  unbind(el) {
    el.mask && el.mask.parentNode && el.mask.parentNode.removeChild(el.mask)
    el.instance && el.instance.$destroy()
  }
})
