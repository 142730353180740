<template>
  <el-form-item v-bind="$attrs" v-on="$listeners">
    <slot v-if="!isView" />
    <div class="text_box" v-else>{{ viewValue }}</div>
  </el-form-item>
</template>

<script>
export default {
  name: 'YplDialogFormItem',
  props: {
    isView: {
      type: Boolean,
      default: false
    },
    viewValue: {
      default: ''
    }
  }
}
</script>
