import Vue from 'vue'
import store from '@/store/index.js'
//自定义指令，用来控制按钮权限
Vue.directive('btnPermission', {
  inserted(el, binding) {
    const currentTag = binding.value.permissionBtn
    // const effect = binding.value.effect
    // console.log(currentTag)
    // console.log(binding)
    // 获取到存放在 store 中的权限数据
    const btnPermission = store.state.permissionList
    // console.log(btnPermission)
    // 判断是否存在对应的按钮权限
    let item = btnPermission.filter(item => {
      return item.permissionCode === currentTag
    })
    //不具备权限则删除（隐藏）或者禁用该按钮 (el.parentNode 获取当前节点的父节点),根据在芫荽绑定的值去决定
    if (item.length === 0) {
      // if (effect === 'disabled') {
      //   el.disabled = true
      //   el.classList.add('is-disabled')
      // } else {
      el.parentNode.removeChild(el)
      // }
    }
  }
})
