import { getRequest, postRequest } from '@/utils/request'

export function getList(obj) {
  return postRequest('/sysData/list', obj)
}
export function getRowById(id) {
  return getRequest(`/sysData/getSysDataById?id=${id}`)
}
export function add(obj) {
  return postRequest(`/sysData/save`, obj)
}
export function edit(obj) {
  return postRequest(`/sysData/update`, obj)
}
export function del(id) {
  return getRequest(`/sysData/delete?id=${id}`)
}
export function getAllList() {
  return getRequest(`/sysData/getAllList`)
}
