<template>
  <div ref="contain_ref" class="contain loading_box">
    <div class="mask"></div>
    <div class="box" :style="isOverFlow ? 'top:200px;' : ''">
      <div class="img_box">
        <img :src="imgUrl" />
      </div>
      <div class="text">加载中</div>
    </div>
  </div>
</template>
<script>
import loading0 from '@/assets/loading/loading0.png'
import loading1 from '@/assets/loading/loading1.png'
import loading2 from '@/assets/loading/loading2.png'
import loading3 from '@/assets/loading/loading3.png'
import loading4 from '@/assets/loading/loading4.png'
import loading5 from '@/assets/loading/loading5.png'
export default {
  data() {
    return {
      loading0,
      loading1,
      loading2,
      loading3,
      loading4,
      loading5,
      imgUrl: loading5,
      interval: null,
      id: 0,
      isOverFlow: false
    }
  },
  methods: {
    setIntervalFun() {
      let maxHeight = window.innerHeight * 0.8
      let relHeight = this.$refs.contain_ref.scrollHeight
      this.isOverFlow = relHeight > maxHeight
      this.interval = setInterval(() => {
        this.imgUrl = this['loading' + this.id]
        if (this.id == 5) {
          this.id = 0
        } else {
          this.id++
        }
        // console.log(this.imgUrl)
      }, 500)
    },
    clearIntervalFun() {
      clearInterval(this.interval)
      this.interval = null
      this.id = 0
      this.imgUrl = loading5
    }
  }
}
</script>

<style lang="scss" scoped>
.contain {
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  z-index: 2000;
  text-align: center;
  position: absolute;
  .mask {
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    opacity: 0.8;
    background: #fff;
  }
  .box {
    position: absolute;
    display: inline-block;
    top: 50%;
    transform: translateY(-50%);
    padding-bottom: 60px;
  }
  .img_box {
    position: relative;
    display: inline-block;
    height: 60px;
    img {
      width: 60px;
      height: 60px;
    }
  }
}
</style>
