<template>
  <ypl-dialog :showButton="item.type == 0" submitText="已 读">
    <div class="notice_content">
      <div class="title">{{ item.title }}</div>
      <div class="content" v-html="item.content"></div>
    </div>
  </ypl-dialog>
</template>

<script>
import dialogMixins from '@/mixins/dialog'
import { updateReadFlag } from '@/api/sys/sysNotice'
export default {
  mixins: [dialogMixins],
  data() {
    return {
      title: '通知详情',
      item: {}
    }
  },
  methods: {
    open(item) {
      console.log(
        item.content
          .split('<img ')
          .map((one, k) => {
            return k > 0 ? `style='max-width: 100%;' ${one}` : one
          })
          .join('<img ')
      )
      this.dialogVisible = true
      this.item = {
        ...item,
        content: item.content
          .split('<img ')
          .map((one, k) => {
            return k > 0 ? `style='max-width: 100%;' ${one}` : one
          })
          .join('<img ')
      }
      console.log(item)
      if (item.type != 0) {
        updateReadFlag(this.item.id).then(res => {
          console.log(res)
          this.$emit('success')
        })
      }
    },
    submitForm() {
      this.handleSubmitForm()
    },
    submit() {
      return updateReadFlag(this.item.id)
    },
    afterSubmit(response) {
      this.dialogVisible = false
      // this.$message({
      //   message: '已读成功',
      //   type: 'success'
      // })
      this.$emit('success', 'announcement')
    }
  }
}
</script>
<style lang="scss" scoped>
.notice_content {
  .title {
    text-align: center;
    font-weight: bold;
    font-size: 18px;
  }
  .content {
    padding: 15px 0;
    min-height: 200px;
  }
}
</style>
