import { getRequest, postRequest } from '@/utils/request'

export function getList(obj) {
  return postRequest('/sysNotice/list', obj)
}
export function getRowById(id) {
  return getRequest(`/sysNotice/getSysNoticeById?id=${id}`)
}
export function add(obj) {
  return postRequest(`/sysNotice/save`, obj)
}
export function edit(obj) {
  return postRequest(`/sysNotice/update`, obj)
}
export function del(id) {
  return getRequest(`/sysNotice/delete?id=${id}`)
}
export function unreadCount() {
  return getRequest(`/sysNotice/unreadCount`)
}
export function getCurrentUserNoticeList() {
  return getRequest(`/sysNotice/getCurrentUserNoticeList`)
}
export function updateReadFlag(id) {
  return getRequest(`/sysNotice/updateReadFlag?id=${id}`)
}
export function getNewProclamation() {
  return getRequest(`/sysNotice/getNewProclamation`)
}
