import Vue from 'vue'
import store from '../store'
import VueRouter from 'vue-router'
import Layout from '@/layout'
import { Message } from 'element-ui'
import { getToken } from '@/utils/auth'

Vue.use(VueRouter)

const routes = [
  {
    path: '/login',
    name: 'login',
    component: resolve => require(['@/views/login'], resolve),
    hidden: true
  },
  {
    path: '/',
    component: Layout,
    redirect: '/inx/inxIndex'
  },
  {
    path: '/inx',
    component: Layout,
    redirect: '/inx/inxIndex',
    children: [
      {
        path: 'inxIndex',
        component: resolve => require(['@/views/inx/inxIndex'], resolve),
        name: 'inxIndex',
        meta: {
          title: '首页',
          path: '/inx/inxIndex',
          icon: 'icon-shouye'
        }
      }
    ]
  },
  {
    path: '/sys',
    component: Layout,
    redirect: '/sys/sysUser',
    meta: {
      title: '基础数据',
      path: '/sys',
      icon: 'icon-jichu'
    },
    children: [
      {
        path: 'sysUser',
        component: resolve => require(['@/views/sys/sysUser'], resolve),
        name: 'sysUser',
        meta: {
          title: '用户管理',
          path: '/sys/sysUser',
          icon: 'icon-yonghu'
        }
      },
      {
        path: 'sysRole',
        component: resolve => require(['@/views/sys/sysRole'], resolve),
        name: 'sysRole',
        meta: {
          title: '角色管理',
          path: '/sys/sysRole',
          icon: 'icon-jiaose'
        }
      },
      {
        path: 'sysCompany',
        component: resolve => require(['@/views/sys/sysCompany'], resolve),
        name: 'sysCompany',
        meta: {
          title: '公司管理',
          path: '/sys/sysCompany',
          icon: 'icon-gongsi'
        }
      },
      {
        path: 'sysDepartment',
        component: resolve => require(['@/views/sys/sysDepartment'], resolve),
        name: 'sysDepartment',
        meta: {
          title: '部门管理',
          path: '/sys/sysDepartment',
          icon: 'icon-bumen'
        }
      },
      {
        path: 'sysNotice',
        component: resolve => require(['@/views/sys/sysNotice'], resolve),
        name: 'sysNotice',
        meta: {
          title: '通知管理',
          path: '/sys/sysNotice',
          icon: 'icon-tongzhi'
        }
      },
      {
        path: 'sysData',
        component: resolve => require(['@/views/sys/sysData'], resolve),
        name: 'sysData',
        meta: {
          title: '数据字典',
          path: '/sys/sysData',
          icon: 'icon-zidian'
        }
      },
      {
        path: 'sysDictionaryData/:id/:dname',
        component: resolve => require(['@/views/sys/sysDictionaryData'], resolve),
        name: 'sysDictionaryData',
        meta: {
          title: '字典数据',
          path: '/sys/sysDictionaryData',
          icon: 'icon-zidian'
        }
      },
      {
        path: 'sysWebLog',
        component: resolve => require(['@/views/sys/sysWebLog'], resolve),
        name: 'sysWebLog',
        meta: {
          title: 'WEB操作日志管理',
          path: '/sys/sysWebLog',
          icon: 'icon-zidian'
        }
      },
      {
        path: 'sysUserLoginLog',
        component: resolve => require(['@/views/sys/sysUserLoginLog'], resolve),
        name: 'sysUserLoginLog',
        meta: {
          title: '登录日志管理',
          path: '/sys/sysUserLoginLog',
          icon: 'icon-zidian'
        }
      },
      {
        path: 'sysWebApiLog',
        component: resolve => require(['@/views/sys/sysWebApiLog'], resolve),
        name: 'sysWebApiLog',
        meta: {
          title: 'API日志管理',
          path: '/sys/sysWebApiLog',
          icon: 'icon-zidian'
        }
      }
    ]
  },
  {
    path: '/cus',
    component: Layout,
    redirect: '/cus/cusCustomer',
    meta: {
      title: '客户关系',
      path: '/cus',
      icon: 'icon-kehuguanxiguanli'
    },
    children: [
      {
        path: 'cusCustomer',
        component: resolve => require(['@/views/cus/cusCustomer'], resolve),
        name: 'cusCustomer',
        meta: {
          title: '客户管理',
          path: '/cus/cusCustomer',
          icon: 'icon-kehu'
        }
      },
      {
        path: 'addcustomer',
        component: resolve => require(['@/views/cus/cusCustomer/addcustomer'], resolve),
        name: 'addcustomer',
        meta: {
          title: '新增线索客户'
        }
      },
      {
        path: 'cusOpenPool',
        component: resolve => require(['@/views/cus/cusOpenPool'], resolve),
        name: 'cusOpenPool',
        meta: {
          title: '公海池',
          path: '/cus/cusOpenPool',
          icon: 'icon-gonghaichi'
        }
      },
      {
        path: 'cusContacts',
        component: resolve => require(['@/views/cus/cusContacts'], resolve),
        name: 'cusContacts',
        meta: {
          title: '联系人管理',
          path: '/cus/cusContacts',
          icon: 'icon-lianxiren'
        }
      },
      {
        path: 'cusActivity',
        component: resolve => require(['@/views/cus/cusActivity'], resolve),
        name: 'cusActivity',
        meta: {
          title: '活动管理',
          path: '/cus/cusActivity',
          icon: 'icon-huodongguanli'
        }
      }
    ]
  },
  {
    path: '/tra',
    component: Layout,
    redirect: '/tra/traBusiness',
    meta: {
      title: '事务处理',
      path: '/tra',
      icon: 'icon-shiwu'
    },
    children: [
      {
        path: 'traBusiness',
        component: resolve => require(['@/views/tra/traBusiness'], resolve),
        name: 'traBusiness',
        meta: {
          title: '商机管理',
          path: '/tra/traBusiness',
          icon: 'icon-shangji'
        }
      },
      {
        path: 'traEvent',
        component: resolve => require(['@/views/tra/traEvent'], resolve),
        name: 'traEvent',
        meta: {
          title: '事件管理',
          path: '/tra/traEvent',
          icon: 'icon-shijian'
        }
      },
      {
        path: 'traCalendar',
        component: resolve => require(['@/views/tra/traCalendar'], resolve),
        name: 'traCalendar',
        meta: {
          title: '日历管理',
          path: '/tra/traCalendar',
          icon: 'icon-rili'
        }
      },
      {
        path: 'traQuota',
        component: resolve => require(['@/views/tra/traQuota'], resolve),
        name: 'traQuota',
        meta: {
          title: '配额管理',
          path: '/tra/traQuota',
          icon: 'icon-peie'
        }
      }
    ]
  },
  {
    path: '/ser',
    component: Layout,
    redirect: '/ser/serWaybill',
    meta: {
      title: '业务数据',
      path: '/ser',
      icon: 'icon-yewu'
    },
    children: [
      {
        path: 'serWaybill',
        component: resolve => require(['@/views/ser/serWaybill'], resolve),
        name: 'serWaybill',
        meta: {
          title: '运单数据',
          path: '/ser/serWaybill',
          icon: 'icon-jianhuo'
        }
      }
    ]
  },
  {
    path: '/cc',
    component: Layout,
    redirect: '/cc/ccPersonalCommitCall',
    meta: {
      title: 'Commit Call',
      path: '/cc',
      icon: 'icon-tongji'
    },
    children: [
      {
        path: 'ccPersonalCommitCall',
        component: resolve => require(['@/views/cc/ccPersonalCommitCall'], resolve),
        name: 'ccPersonalCommitCall',
        meta: {
          title: '个人Commit Call',
          path: '/cc/ccPersonalCommitCall',
          icon: 'icon-geren'
        }
      },
      {
        path: 'ccDepartmentCommitCall',
        component: resolve => require(['@/views/cc/ccDepartmentCommitCall'], resolve),
        name: 'ccDepartmentCommitCall',
        meta: {
          title: '团队Commit Call',
          path: '/cc/ccDepartmentCommitCall',
          icon: 'icon-tuandui'
        }
      },
      {
        path: 'ccCompanyCommitCall',
        component: resolve => require(['@/views/cc/ccCompanyCommitCall'], resolve),
        name: 'ccCompanyCommitCall',
        meta: {
          title: '公司Commit Call',
          path: '/cc/ccCompanyCommitCall',
          icon: 'icon-gongsi'
        }
      },
      {
        path: 'ccAnalysisCommitCall',
        component: resolve => require(['@/views/cc/ccAnalysisCommitCall'], resolve),
        name: 'ccAnalysisCommitCall',
        meta: {
          title: '差异分析commit call',
          path: '/cc/ccAnalysisCommitCall',
          icon: 'icon-chayifenxi'
        }
      }
    ]
  }
]

// 获取原型对象push函数
const originalPush = VueRouter.prototype.push

// 获取原型对象replace函数
const originalReplace = VueRouter.prototype.replace

// 修改原型对象中的push函数
VueRouter.prototype.push = function push(location) {
  return originalPush.call(this, location).catch(err => err)
}

// 修改原型对象中的replace函数
VueRouter.prototype.replace = function replace(location) {
  return originalReplace.call(this, location).catch(err => err)
}

const router = new VueRouter({
  mode: 'history',
  routes
})

const whiteList = ['/login']

let isWx = window.navigator.userAgent.includes('MicroMessenger')

function RouterPermissionFun(arr, url) {
  let isHave = false
  arr?.forEach(one => {
    if (one.menuUrl == url) {
      isHave = true
    } else if (url.indexOf('/sys/sysDictionaryData') != -1 && one.menuUrl == '/sys/sysData') {
      //数据字典附带页面
      isHave = true
    } else if (url.indexOf('/cus/addcustomer') != -1 && one.menuUrl == '/cus/cusCustomer') {
      //线索客户新增页面
      isHave = true
    }
    if (one.childList && one.childList.length) {
      if (!isHave) {
        isHave = RouterPermissionFun(one.childList, url)
      }
    }
  })
  return isHave
}

router.beforeEach((to, from, next) => {
  // let fullPath = to.fullPath
  const redirectUrl = encodeURIComponent(`${window.location.origin}`) // 授权登陆后的回调地址，需要进行encodeURIComponent处理
  const appId = 'wwad8c8065f0fc521a'
  const scope = 'snsapi_base' // 授权类型，snsapi_base(静默授权)或snsapi_privateinfo(手动授权)
  const urlState = '123' // 用于防止CSRF攻击，可以是任意字符串
  const agentId = process.env.VUE_APP_Agentid // 企业内应用id
  const openUrl = `https://open.weixin.qq.com/connect/oauth2/authorize?appid=${appId}&redirect_uri=${redirectUrl}&response_type=code&scope=${scope}&state=${urlState}&agentid=${agentId}#wechat_redirect`
  const code = to.query.code // 链接中code
  console.log(to)
  console.log(from)
  console.log(store.state.menuList)
  if (getToken()) {
    /* has token*/
    if (to.path === '/login') {
      next({ path: '/' })
    } else {
      //判断当前用户是否已拉取完user_info信息
      store
        .dispatch('GetUserInfo')
        .then(res => {
          //判断to路由是否有权限
          let isHaveRouterPermission = RouterPermissionFun(store.state.menuList, to.path)
          if (isHaveRouterPermission) {
            console.log(to)
            let newItem = {
              title: to.meta.title,
              path: to.path,
              name: to.name,
              dname: to.params?.dname
            }
            store.commit('addVisitedRoutes', newItem)
            //获取数据字典
            if (!store.state.getsysData) {
              store.dispatch('GetsysData')
            }
            //获取用户列表数据
            if (!store.state.getUserData) {
              store.dispatch('GetUserData')
            }
            next()
          } else {
            Message({
              message: '您的账号没有权限访问该页面',
              type: 'warning'
            })
            if (RouterPermissionFun(store.state.menuList, from.path)) {
              next(from.path)
            } else {
              if (store.state.menuList?.length) {
                next(store.state.menuList[0].menuUrl)
              } else {
                store.dispatch('LogOut').then(() => {
                  next(`/login`)
                })
              }
            }
          }
        })
        .catch(err => {
          // store
          //   .dispatch('LogOut')
          //   .then(() => {
          //     next({ path: '/' })
          //   })
          //   .catch(err => console.log(err))
        })
    }
  } else {
    // 没有token
    if (code && isWx) {
      const fd = {
        code: code
        // state: state
      }
      store.dispatch('wxLogin', fd).then(res => {
        //判断当前用户是否已拉取完user_info信息
        store
          .dispatch('GetUserInfo')
          .then(res => {
            //判断to路由是否有权限
            let isHaveRouterPermission = RouterPermissionFun(store.state.menuList, to.path)
            if (isHaveRouterPermission) {
              console.log(to)
              let newItem = {
                title: to.meta.title,
                path: to.path,
                name: to.name,
                dname: to.params?.dname
              }
              store.commit('addVisitedRoutes', newItem)
              //获取数据字典
              if (!store.state.getsysData) {
                store.dispatch('GetsysData')
              }
              //获取用户列表数据
              if (!store.state.getUserData) {
                store.dispatch('GetUserData')
              }
              if (to.path === '/login') {
                next({ path: '/' })
              } else {
                next()
              }
            } else {
              Message({
                message: '您的账号没有权限访问该页面',
                type: 'warning'
              })
              if (RouterPermissionFun(store.state.menuList, from.path)) {
                next(from.path)
              } else {
                if (store.state.menuList?.length) {
                  next(store.state.menuList[0].menuUrl)
                } else {
                  store.dispatch('LogOut').then(() => {
                    next(`/login`)
                  })
                }
              }
            }
          })
          .catch(err => {
            // store
            //   .dispatch('LogOut')
            //   .then(() => {
            //     next({ path: '/' })
            //   })
            //   .catch(err => console.log(err))
          })
      })
    } else if (whiteList.indexOf(to.path) !== -1) {
      // 在免登录白名单，直接进入
      next()
    } else {
      if (isWx) {
        window.location.href = openUrl
      } else {
        next(`/login`) // 否则全部重定向到登录页
      }
      // next()
    }
  }
})

export default router
