const TokenKey = 'Admin-Token'
export function getToken() {
  return window.localStorage.getItem(TokenKey)
}

export function setToken(token) {
  console.info('-----------auth.js setTOken(),token=' + token)
  return window.localStorage.setItem(TokenKey, token)
}

export function removeToken() {
  return window.localStorage.removeItem(TokenKey)
}
