import axios from 'axios'
import { Notification, MessageBox, Message } from 'element-ui'
import store from '@/store'
import { getToken } from '@/utils/auth'

let BASE_URL = '/server'
let isWx = window.navigator.userAgent.includes('MicroMessenger')

axios.defaults.headers['Content-Type'] = 'application/json;charset=utf-8'
// 超时时间 /10000毫秒
// if (process.env.NODE_ENV === 'production') {
//   axios.defaults.timeout = 10000
// }

// request拦截器
axios.interceptors.request.use(
  config => {
    if (getToken()) {
      config.headers['token'] = getToken()
    }
    return config
  },
  error => {
    console.log(error)
    Promise.reject(error)
  }
)

// 响应拦截器
axios.interceptors.response.use(
  res => {
    console.log(res)
    // 未设置状态码则默认成功状态
    const code = res.data.code || 200
    // 获取错误信息
    const msg = res.data.message
    if (code === 401) {
      if (isWx) {
        store.dispatch('LogOut').then(() => {
          location.reload()
        })
      } else {
        MessageBox.confirm('登录状态已过期，您可以继续留在该页面，或者重新登录', '系统提示', {
          confirmButtonText: '重新登录',
          cancelButtonText: '取消',
          type: 'warning'
        }).then(() => {
          store.dispatch('LogOut').then(() => {
            location.reload()
          })
        })
      }
    } else if (code === 500) {
      Message({
        message: msg,
        type: 'error',
        duration: 5 * 1000,
        center: true
      })
      return Promise.reject(res.data)
    } else if (code !== 200) {
      Notification.error({
        title: msg
      })
      return Promise.reject('error')
    } else {
      if (res.headers['content-disposition']) {
        return res
      } else {
        return res.data
      }
    }
  },
  error => {
    // console.log('err' + error)
    // let { message } = error
    // if (message == 'Network Error') {
    //   message = '后端接口连接异常'
    // } else if (message.includes('timeout')) {
    //   message = '系统接口请求超时'
    // } else if (message.includes('Request failed with status code')) {
    //   console.log(message.substr(message.length - 3))
    //   if (message.substr(message.length - 3) == '500') {
    //     message = '正在更新，请稍后再试'
    //   } else {
    //     message = '系统接口' + message.substr(message.length - 3) + '异常'
    //   }
    // }
    Message({
      message: '正在更新，请稍后再试',
      type: 'error',
      duration: 5 * 1000
    })
    return Promise.reject(error)
  }
)

export const postRequest = (url, params, option) => {
  return new Promise((resolve, reject) => {
    axios
      .post(`${BASE_URL}${url}`, JSON.stringify(params), option)
      .then(res => {
        console.log('post封装信息的res：', res)
        resolve(res)
      })
      .catch(err => {
        reject(err)
      })
  })
}

export const getRequest = (url, params, option) => {
  return new Promise((resolve, reject) => {
    let config = Object.assign({}, option)
    config.params = params || {}
    axios
      .get(`${BASE_URL}${url}`, config)
      .then(res => {
        console.log('get封装信息的res：', res)
        resolve(res)
      })
      .catch(err => {
        reject(err)
      })
  })
}

export const exportRequest = (url, params) => {
  return new Promise((resolve, reject) => {
    axios({
      url: `${BASE_URL}${url}`,
      method: params ? 'post' : 'get',
      responseType: 'blob',
      data: params ? JSON.stringify(params) : ''
    })
      // axios.get(`${BASE_URL}${url}`, { responseType: 'blob' })
      .then(res => {
        console.log(res.data)
        let name = decodeURIComponent(res.headers?.['content-disposition']?.split('filename=')[1])
        console.log(name)
        var blob = new Blob([res.data], {
          type: 'application/vnd.ms-excel;charset=utf-8'
        })
        // 创建一个blob的对象链接
        const url = window.URL.createObjectURL(blob)
        const link = document.createElement('a')
        link.style.display = 'none'
        link.href = url
        // 把获得的blob的对象链接赋值给新创建的这个 a 链接
        link.setAttribute('download', name) // 设置下载文件名
        document.body.appendChild(link)
        // 使用js点击这个链接
        link.click()
        document.body.removeChild(link) // 下载完成移除元素
        window.URL.revokeObjectURL(url) // 释放blob对象
      })
      .catch(err => {
        Message({
          message: '文件下载失败',
          type: 'error',
          duration: 5 * 1000
        })
        reject(err)
      })
  })
}
export const importRequest = (url, file) => {
  let formData = new FormData()
  let headers = {
    'Content-Type': 'multipart/form-data'
  }
  formData.append('file', file)
  return new Promise((resolve, reject) => {
    axios
      .post(`${BASE_URL}${url}`, formData, { headers })
      .then(res => {
        resolve(res)
      })
      .catch(err => {
        reject(err)
      })
  })
}
