<template>
  <div class="fixed_header" :class="!menuOpend ? 'hide_menu' : ''">
    <div class="navbar">
      <div class="navbar_left">
        <hamburger id="hamburger-container" :is-active="menuOpend" class="hamburger-container" @toggleClick="toggleSideBar" />
        <div class="breadcrumb_box">
          <el-breadcrumb separator="/">
            <el-breadcrumb-item v-for="(item, index) in levelList" :key="index">
              <span class="no_redirect" v-if="index == levelList.length - 1">{{ item.meta.title }}</span>
              <a v-else @click.prevent="handleLink(item)">{{ item.meta.title }}</a>
            </el-breadcrumb-item>
          </el-breadcrumb>
        </div>
      </div>
      <div class="navbar_right">
        <el-dropdown class="avatar-container right-menu-item hover-effect" trigger="click">
          <div class="avatar-wrapper">
            <!-- <img :src="headIcon" class="user-avatar" /> -->
            {{ $store.state.userOption.username }}
            <i class="el-icon-caret-bottom" />
          </div>
          <el-dropdown-menu slot="dropdown">
            <el-dropdown-item @click.native="logout">
              <span>退出登录</span>
            </el-dropdown-item>
            <el-dropdown-item @click.native="updatePassword">
              <span>修改密码</span>
            </el-dropdown-item>
          </el-dropdown-menu>
        </el-dropdown>
        <div class="theme">
          <div class="theme_circle" @click="themeFun">
            <i class="iconfont icon-ai250" v-if="!isDark"></i>
            <i class="iconfont icon-yueliang" v-else></i>
          </div>
        </div>
        <div class="icon_box">
          <el-badge :value="unreadCount" :max="99" :hidden="unreadCount == 0" @click.native="noticeFun">
            <i class="el-icon-bell" @click.stop="noticeFun"></i>
          </el-badge>
        </div>
      </div>
    </div>
    <div class="tagsViewContainer">
      <el-scrollbar ref="scroll" class="tags_scrollbar" @wheel.native.prevent="changeScroll">
        <div class="tags_box">
          <el-tag v-for="one in visitedRoutesList" :key="one.path" :effect="$route.path == one.path ? 'dark' : 'plain'" closable size="medium" @click="toPath(one)" @close="del(one)">{{ one.dname || one.title }}</el-tag>
        </div>
      </el-scrollbar>
    </div>
    <!--修改密码-->
    <update-password ref="updatePassword" :appendToBody="true"></update-password>
    <!--消息通知-->
    <el-drawer title="消息通知" size="25%" :visible.sync="drawer" class="drawer" direction="rtl" append-to-body @close="close">
      <div class="notice_box" :class="item.readFlag ? 'readFlag' : ''" v-for="item in currentUserNoticeList" :key="item.id" @click="toNoticeDetail(item)">
        <div class="title_box">
          <div class="title_left">{{ item.title }}</div>
          <div class="title_right">{{ item.createTime }}</div>
        </div>
        <!-- <div class="contain">{{ item.title }}</div> -->
      </div>
    </el-drawer>
    <notice-content width="65%" ref="noticeContent" :appendToBody="true" @success="noticeFun"></notice-content>
  </div>
</template>
<script>
import Hamburger from '@/components/Hamburger'
import updatePassword from '@/components/updatePassword'
import noticeContent from '@/components/noticeContent'
import { mapState } from 'vuex'
import { unreadCount, getCurrentUserNoticeList, getNewProclamation } from '@/api/sys/sysNotice'
export default {
  data() {
    return {
      levelList: [],
      drawer: false,
      unreadCount: 0,
      currentUserNoticeList: []
    }
  },
  created() {
    this.getUnreadCount()
  },
  mounted() {
    this.judgmentPassword()
    this.getNewProclamationFun()
  },
  components: {
    Hamburger,
    updatePassword,
    noticeContent
  },
  computed: {
    ...mapState(['menuOpend', 'visitedRoutesList', 'isDark'])
  },
  watch: {
    $route: {
      handler(route) {
        this.levelList = route.matched.filter(one => one.meta && one.meta.title)
      },
      immediate: true
    }
  },
  methods: {
    themeFun() {
      this.$store.commit('setDark')
    },
    changeScroll(e) {
      let wrap = this.$refs.scroll.$refs.wrap
      wrap.scrollLeft = wrap.scrollLeft - e.wheelDelta
    },
    toggleSideBar() {
      this.$store.commit('setMenuOpend')
    },
    handleLink(item) {
      let path = item.redirect
      if (path) {
        this.$router.push(path)
      }
    },
    toPath(item) {
      this.$router.push(item.path)
    },
    del(item) {
      let visitedRoutesList = this.$store.state.visitedRoutesList
      if (visitedRoutesList.length > 1) {
        let key = visitedRoutesList.findIndex(one => one.path == item.path)
        this.$store.dispatch('DelVisitedRoutesList', item).then(() => {
          if (this.$route.path == item.path) {
            if (key > 0) {
              this.$router.push(visitedRoutesList[key - 1])
            } else {
              this.$router.push(visitedRoutesList[visitedRoutesList.length - 1])
            }
          }
        })
      }
    },
    logout() {
      this.$store.dispatch('LogOut').then(() => {
        this.$router.push('/login')
      })
    },
    updatePassword() {
      this.$refs.updatePassword.open()
    },
    noticeFun(type) {
      if (type == 'announcement') {
        this.getUnreadCount()
        return false
      }
      this.drawer = true
      getCurrentUserNoticeList().then(res => {
        if (res.data) {
          res.data?.map(one => {
            one.createTime = one.createTime?.split(' ')[0]
            if (one.createUserId) {
              this.$store.dispatch('GetUserDataById', one.createUserId).then(res => {
                this.$set(one, 'createUserName', res.cnName)
              })
            }
          })
          this.currentUserNoticeList = res.data
        }
      })
    },
    getUnreadCount() {
      unreadCount().then(res => {
        if (res.data !== null) {
          this.unreadCount = res.data
        }
      })
    },
    toNoticeDetail(item) {
      this.$refs.noticeContent.open(item)
    },
    judgmentPassword() {
      if (this.$store.state.userOption.updatePasswordFlag) {
        this.$refs.updatePassword.open({ updatePasswordFlag: true })
      }
    },
    getNewProclamationFun() {
      getNewProclamation().then(res => {
        if (res.data) {
          this.toNoticeDetail(res.data)
        }
      })
    },
    close() {
      this.getUnreadCount()
    }
  }
}
</script>
<style lang="scss" scoped>
.fixed_header {
  position: fixed;
  top: 0;
  right: 0;
  z-index: 9;
  width: calc(100% - 200px);
  transition: width 0.28s;
  &.hide_menu {
    width: calc(100% - 54px);
  }
  .navbar {
    height: 50px;
    overflow: hidden;
    position: relative;
    background: #fff;
    box-shadow: 0 1px 4px rgba(0, 21, 41, 0.08);
    display: flex;
    justify-content: space-between;
    align-items: center;
    .navbar_left {
      display: inline-flex;
      align-items: center;
      .hamburger-container {
        line-height: 46px;
        height: 100%;
        cursor: pointer;
        transition: background 0.3s;
        &:hover {
          background: rgba(0, 0, 0, 0.025);
        }
      }
      .breadcrumb_box {
        font-weight: 400 !important;
        color: #5a5e66;
        .no_redirect {
          color: #97a8be;
        }
      }
    }
    .navbar_right {
      padding-right: 15px;
      cursor: pointer;
    }
  }

  .tagsViewContainer {
    height: 37px;
    background: #fff;
    border-bottom: 1px solid #d8dce5;
    box-shadow: 0 1px 3px 0 rgba(0, 0, 0, 0.12), 0 0 3px 0 rgba(0, 0, 0, 0.04);
    .tags_scrollbar {
      width: 100%;
      .tags_box {
        display: inline-block;
        padding: 4px 8px;
        white-space: nowrap;
        .el-tag {
          cursor: pointer;
          + .el-tag {
            cursor: pointer;
            margin-left: 5px;
          }
        }
        ::v-deep .el-icon-close {
          font-size: 12px;
          height: 13px;
          width: 13px;
          line-height: 13px;
          transform: scale(1);
        }
      }
    }
  }
}
.drawer {
  ::v-deep .el-drawer__header {
    margin-bottom: 0;
    padding: 10px 20px;
    position: relative;
    &::after {
      content: '';
      height: 1px;
      background-color: #c0c4cc;
      width: 100%;
      position: absolute;
      bottom: 0;
      left: 0;
    }
  }
  .notice_box {
    cursor: pointer;
    padding: 10px 15px;
    border-bottom: 1px solid #c0c4cc;
    &.readFlag {
      color: #c0c4cc;
    }
    .title_box {
      display: flex;
      justify-content: space-between;
      align-items: center;
      .title_left {
        font-size: 14px;
        flex: 1;
        white-space: nowrap;
        text-overflow: ellipsis;
        overflow: hidden;
      }
      .title_right {
        color: #72767b;
        font-size: 13px;
        padding-left: 5px;
      }
    }
    // .contain {
    // }
  }
}
</style>
<style lang="scss">
.fixed_header {
  .navbar_left {
    .breadcrumb_box {
      a {
        font-weight: 400 !important;
        color: #5a5e66;
      }
    }
  }
  .navbar_right {
    display: inline-flex;
    align-items: center;
    .theme {
      // display: inline-block;
      padding: 0 5px;
      .theme_circle {
        min-width: 40px;
        height: 20px;
        border: 1px solid #dcdfe6;
        border-radius: 10px;
        background: #f2f2f2;
        position: relative;
        .iconfont {
          color: #606266;
          position: absolute;
          top: 0;
          bottom: 0;
          display: inline-flex;
          align-items: center;
          &.icon-ai250 {
            left: 0;
          }
          &.icon-yueliang {
            right: 0;
          }
        }
      }
    }
    .icon_box {
      display: inline-block;
      padding: 0 15px 0 0;
      .el-icon-bell {
        font-size: 18px;
      }
    }
    .avatar-container {
      font-size: 16px;
    }
  }
  .tagsViewContainer {
    .el-scrollbar__wrap {
      height: 53px;
    }
  }
}
</style>
