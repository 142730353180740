<template>
  <el-input @blur="blurFun" v-bind="$attrs" v-on="$listeners">
    <template v-for="(val, key) in $slots" #[key]>
      <slot :name="key"></slot>
    </template>
  </el-input>
</template>
<script>
export default {
  name: 'YplInput',
  props: {
    // value: {
    //   type: [String, Number],
    //   default: ''
    // }
  },
  methods: {
    // handleInput(value) {
    //   this.$emit('input', value)
    // }
    blurFun(event) {
      let newVal = event.target.value.trim()
      if (event.target.value != newVal) {
        this.$emit('input', newVal)
      }
    }
  }
}
</script>
