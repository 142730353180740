import { saveWebLog } from '@/api/sys/sysWebLog'
import { getToken } from '@/utils/auth'
export default {
  props: {
    width: {
      type: String,
      default: '800px'
    },
    closeOnClickModal: {
      type: Boolean,
      default: false
    },
    closeOnPressEscape: {
      type: Boolean,
      default: false
    },
    appendToBody: {
      type: Boolean,
      default: false
    },
    type: {
      type: String,
      default: 'dialog' // dialog 普通弹窗  page 新开一页弹窗
    }
  },
  data() {
    return {
      title: '新增',
      form: {},
      rules: {},
      dialogVisible: false,
      fullscreen: false, //全屏
      id: '',
      option: {}, //父组件传过来的配置项
      labelWidth: '80px',
      loading: false, //遮罩层
      submitting: false, //提交中
      showClose: true, //显示关闭按钮
      isDialog: true,
      isView: false,
      gutter: 20, //表单间距
      pathName: '', //page 新开一页弹窗跳转回主页面

      //操作日志
      isSuccessClosed: false, //成功关闭弹窗不调关闭接口，避免重复
      oldForm: null,
      isSaveWebLog: false,
      functionAddId: '', //新增id
      functionUpdateId: '', //修改id
      functionViewId: '' //详情id
    }
  },
  computed: {
    span() {
      return this.fullscreen ? 8 : 12
    },
    viewSpan() {
      return this.fullscreen ? 3 : 2
    }
  },
  methods: {
    getData(id) {
      return new Promise(res => {
        res()
      })
    },
    afterGetData(res) {
      this.form = Object.assign({}, this.form, res.data)
    },
    beforeOpen(cb, id) {
      cb(id)
    },
    afterOpen() {},
    open(id, option) {
      console.log(id)
      console.log(option)
      //打开弹窗
      this.dialogVisible = true
      this.id = id
      this.option = option
      if (this.id) {
        this.title = '修改'
      }
      if (this.option?.title) {
        this.title = this.option.title
      }
      if (this.option?.isView !== undefined) {
        this.isView = this.option.isView
      }
      let cb = id => {
        if (id) {
          this.loading = true
          this.getData(id)
            .then(res => {
              this.loading = false
              this.afterGetData(res)
              this.oldForm = res
            })
            .catch(res => {
              this.oldForm = res
            })
            .finally(() => {
              if (this.isView) {
                let logObj = {
                  functionId: this.option.functionId ? this.option.functionId : this.functionViewId,
                  params: JSON.stringify(this.option.row),
                  result: JSON.stringify(this.oldForm)
                }
                this.saveWebLogFun(logObj)
              }
            })
        }
        this.afterOpen()
      }
      this.beforeOpen(cb, id)
    },
    close() {
      //关闭弹窗
      this.dialogVisible = false
      if (!this.isSuccessClosed && !this.isView) {
        this.saveWebLogFunBefore({ params: JSON.stringify(this.oldForm), isclose: true })
      }
      this.$emit('close')
      if (this.type == 'page') {
        console.log(this.$route)
        this.$store.dispatch('DelVisitedRoutesList', { path: this.$route.path }).then(() => {
          this.$router.push({
            name: this.pathName,
            params: {
              type: 'close'
            }
          })
        })
        this.closed()
      }
    },
    submit() {},
    beforeSubmit() {
      return true
    },
    afterSubmit(res) {
      this.isSuccessClosed = true
      this.dialogVisible = false
      this.$message({
        message: '保存成功',
        type: 'success'
      })
      this.$emit('success')
      if (this.type == 'page') {
        this.$store.dispatch('DelVisitedRoutesList', { path: this.$route.path }).then(() => {
          this.$router.push({
            name: this.pathName,
            params: {
              type: 'success'
            }
          })
        })
        this.closed()
      }
    },
    submitForm() {
      console.log(this.form)
      //提交
      this.$refs['ypl-dialog-form'].$refs.form.validate(valid => {
        console.log(valid)
        if (valid) {
          this.handleSubmitForm(...arguments)
        } else {
          setTimeout(() => {
            let errorItem = document.getElementsByClassName('is-error')
            errorItem && errorItem[0].scrollIntoView()
            let eleListHTML = document.getElementsByClassName('el-form-item__error')
            if (eleListHTML) {
              eleListHTML = Array.from(eleListHTML)
                ?.map(one => one.innerText)
                ?.join(',')
              this.saveWebLogFunBefore({ result: JSON.stringify(eleListHTML) })
            }
          }, 10)
        }
      })
    },
    saveWebLogFunBefore(obj) {
      let logObj = {
        functionId: obj.isclose ? (this.id ? this.functionUpdateCancelId : this.functionAddCancelId) : this.id ? this.functionUpdateSaveId : this.functionAddSaveId,
        params: JSON.stringify(this.form),
        ...obj
      }
      this.saveWebLogFun(logObj)
    },
    handleSubmitForm() {
      let cb = () => {
        let logObj = {}
        this.submitting = true
        this.submit(...arguments)
          .then(res => {
            this.afterSubmit(res)
            logObj.result = JSON.stringify(res)
          })
          .catch(res => {
            logObj.result = JSON.stringify(res)
          })
          .finally(() => {
            this.submitting = false
            //过滤编辑接口参数
            if (this.id) {
              logObj.params = JSON.stringify(this.filterPortPropFun(this.form))
            }
            this.saveWebLogFunBefore(logObj)
          })
      }
      if (this.beforeSubmit(cb)) {
        cb()
      }
    },
    closed() {
      if (this.rules) {
        Object.assign(this, { ...this.$options.data.call(this), rules: this.rules })
      } else {
        Object.assign(this, this.$options.data.call(this))
      }
      // console.log(this.form)
      this.$nextTick(function () {
        if (this.$refs['ypl-dialog-form'] && this.$refs['ypl-dialog-form'].$refs.form) {
          this.$refs['ypl-dialog-form'].$refs.form.clearValidate()
        }
      })
      this.afterResetFields()
    },
    afterResetFields() {},
    openDialog(ref, data, option) {
      //打开弹窗
      this.$refs[ref].open(data, option ? option : {})
    },
    opened() {
      if (this.$refs['ypl-table']) {
        this.$refs['ypl-table'].resetTableHeight({ fullscreen: this.fullscreen })
      }
    },
    //过滤修改接口字段--只传接口需要的
    filterPortPropFun(obj) {
      if (!this.portProp) {
        return obj
      } else {
        let newObj = {}
        Object.keys(obj).forEach(one => {
          if (this.portProp.some(item => item == one)) {
            newObj[one] = obj[one]
          }
        })
        return newObj
      }
    },
    //通过列表返现文字
    dictionaryText(list, value, option) {
      let dlabel = 'label'
      let dvalue = 'value'
      if (option) {
        dlabel = option.label || 'label'
        dvalue = option.value || 'value'
      }
      if (Array.isArray(value)) {
        return value
          .map(item => {
            return list.find(one => one[dvalue] == item)?.[dlabel] || ''
          })
          .join(',')
      } else {
        return list.find(one => one[dvalue] == value)?.[dlabel] || ''
      }
    },
    //操作日志
    saveWebLogFun(obj) {
      if (!this.isSaveWebLog) {
        return
      }
      let newObj = {
        ...obj,
        ...this.$store.state.operationInfor,
        operationTime: new Date().format('yyyy-MM-dd hh:mm:ss'),
        token: getToken()
      }
      saveWebLog([newObj])
    }
  },
  mounted() {},
  deactivated() {
    this.dialogVisible = false
  }
}
