<template>
  <div>
    <el-menu-item v-if="!routeItem.childList || !routeItem.childList.length" :index="routeItem.menuUrl" @click="toPath(routeItem)">
      <i class="iconfont" :class="routeItem.icon"></i>
      <span slot="title">{{ routeItem.menuName }}</span>
    </el-menu-item>
    <el-submenu v-else :index="routeItem.menuUrl" popper-append-to-body>
      <template slot="title">
        <i class="iconfont" :class="routeItem.icon"></i>
        <span slot="title">{{ routeItem.menuName }}</span>
      </template>
      <menu-item v-for="child in routeItem.childList" :key="child.menuUrl" :routeItem="child" class="submenu_item" />
    </el-submenu>
  </div>
</template>
<script>
export default {
  name: 'menuItem',
  data() {
    return {}
  },
  props: {
    routeItem: {
      type: Object,
      required: true
    }
  },
  methods: {
    toPath(routeItem) {
      console.log(routeItem)
      this.$router.push(routeItem.menuUrl)
    }
  }
}
</script>
<style lang="scss">
.el-menu-item,
.el-submenu__title {
  background-color: #ffffff !important;
  &:hover {
    background-color: rgba(0, 0, 0, 0.06) !important;
  }
  .iconfont {
    color: rgb(64, 158, 255);
    padding-right: 4px;
  }
}
</style>
