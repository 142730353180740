<template>
  <el-pagination class="pagination-container" v-bind="$attrs" v-on="$listeners" :background="background" :layout="layout" :page-sizes="pageSizes" />
</template>
<script>
export default {
  name: 'Pagination',
  props: {
    pageSizes: {
      type: Array,
      default() {
        return [10, 20, 30, 50]
      }
    },
    layout: {
      type: String,
      default: 'total, sizes, prev, pager, next, jumper'
    },
    background: {
      type: Boolean,
      default: true
    }
  },
  methods: {}
}
</script>

<style scoped>
.pagination-container {
  background: #fff;
  padding: 10px 10px 0;
  text-align: right;
}
</style>
