<template>
  <el-date-picker v-model="value" :type="type" :unlink-panels="unlinkPanels" :value-format="valueFormat ? valueFormat : type == 'daterange' ? 'yyyy-MM-dd' : 'yyyy-MM-dd HH:mm:ss'" :range-separator="rangeSeparator" :start-placeholder="startPlaceholder" :end-placeholder="endPlaceholder" :picker-options="pickerOptions" v-bind="$attrs" v-on="$listeners" />
</template>

<script>
export default {
  name: 'YplDatetimerangePicker',
  props: {
    start: {
      required: true
    },
    end: {
      required: true
    },
    type: {
      type: String,
      default: 'daterange' // daterange or datetimerange
    },
    startPlaceholder: {
      type: String,
      default: '开始日期'
    },
    endPlaceholder: {
      type: String,
      default: '结束日期'
    },
    rangeSeparator: {
      type: String,
      default: '至'
    },
    unlinkPanels: {
      type: Boolean,
      default: true
    },
    pickerOptions: {
      type: Object,
      default: () => {
        return {
          shortcuts: [
            {
              text: '最近一周',
              onClick(picker) {
                const end = new Date()
                const start = new Date()
                start.setTime(start.getTime() - 3600 * 1000 * 24 * 7)
                picker.$emit('pick', [start, end])
              }
            },
            {
              text: '最近一个月',
              onClick(picker) {
                const end = new Date()
                const start = new Date()
                start.setTime(start.getTime() - 3600 * 1000 * 24 * 30)
                picker.$emit('pick', [start, end])
              }
            },
            {
              text: '最近三个月',
              onClick(picker) {
                const end = new Date()
                const start = new Date()
                start.setTime(start.getTime() - 3600 * 1000 * 24 * 90)
                picker.$emit('pick', [start, end])
              }
            }
          ]
        }
      }
    },
    valueFormat: {
      type: String
    }
  },
  computed: {
    value: {
      get() {
        return [this.start || '', this.end || '']
      },
      set(val) {
        this.$emit('update:start', val ? val[0] : '')
        this.$emit('update:end', val ? val[1] : '')
      }
    }
  }
}
</script>
