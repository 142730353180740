import Vue from 'vue'
import ElementUI from 'element-ui'
import 'element-ui/lib/theme-chalk/index.css'
import UmyUi from 'umy-ui'
import 'umy-ui/lib/theme-chalk/index.css'
import App from './App.vue'
import router from './router'
import store from './store'

import '@/assets/style/element.scss'
import '@/assets/style/darkly.scss'
import '@/assets/iconfont/iconfont.css'
//富文本样式
import 'quill/dist/quill.core.css'
import 'quill/dist/quill.snow.css'
import 'quill/dist/quill.bubble.css'

//自定义指令
// import './directive/collect' //埋点
import './directive/loading'
import './directive/btnPermission'
import './directive/dragDialog'

//自定义组件全局挂载
import YplForm from '@/components/YplForm'
Vue.component('YplForm', YplForm)
import YplTable from '@/components/YplTable'
Vue.component('YplTable', YplTable)
import YplTableColumn from '@/components/YplTableColumn'
Vue.component('YplTableColumn', YplTableColumn)
import YplTableVirtual from '@/components/YplTableVirtual'
Vue.component('YplTableVirtual', YplTableVirtual)
import YplTableColumnVirtual from '@/components/YplTableColumnVirtual'
Vue.component('YplTableColumnVirtual', YplTableColumnVirtual)
import YplDialog from '@/components/YplDialog'
Vue.component('YplDialog', YplDialog)
import YplPagination from '@/components/YplPagination'
Vue.component('YplPagination', YplPagination)
import YplDialogForm from '@/components/YplDialogForm'
Vue.component('YplDialogForm', YplDialogForm)
import YplDialogFormItem from '@/components/YplDialogFormItem'
Vue.component('YplDialogFormItem', YplDialogFormItem)
import YplDatetimerangePicker from '@/components/YplDatetimerangePicker'
Vue.component('YplDatetimerangePicker', YplDatetimerangePicker)
import YplTimePicker from '@/components/YplTimePicker'
Vue.component('YplTimePicker', YplTimePicker)
import YplInput from '@/components/YplInput'
Vue.component('YplInput', YplInput)
import YplDescriptions from '@/components/YplDescriptions'
Vue.component('YplDescriptions', YplDescriptions)
import YplDescriptionsItem from '@/components/YplDescriptionsItem'
Vue.component('YplDescriptionsItem', YplDescriptionsItem)

//echarts
import echarts from '@/components/echarts'
Vue.prototype.$echarts = echarts

import Decimal from 'decimal.js' //精度问题
window.Decimal = Decimal

//utils
import { intervalDateByYearWeek, intervalYearWeekByDate, maxWeekByYear, keepTwoDecimal } from '@/utils/handleData.js'
Vue.prototype.intervalDateByYearWeek = intervalDateByYearWeek
Vue.prototype.intervalYearWeekByDate = intervalYearWeekByDate
Vue.prototype.maxWeekByYear = maxWeekByYear
Vue.prototype.keepTwoDecimal = keepTwoDecimal

//全局过滤
Vue.filter('moneyFormat', value => {
  return new Decimal(value ? value : 0).toFixed(2)
})

Vue.use(UmyUi)
Vue.use(ElementUI, {
  size: 'medium' // set element-ui default size
})
Vue.config.productionTip = false

new Vue({
  router,
  store,
  render: h => h(App)
}).$mount('#app')
