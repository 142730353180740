<template>
  <el-descriptions border :column="column" v-bind="$attrs" v-on="$listeners" labelClassName="description_label_class" contentClassName="description_content_class">
    <slot />
  </el-descriptions>
</template>

<script>
export default {
  name: 'YplDescriptions',
  props: {
    column: {
      type: Number,
      default: 2
    }
  },
  data() {
    return {}
  },
  methods: {},
  mounted() {},
  computed: {},
  watch: {}
}
</script>
<style lang="scss" scoped></style>
