<template>
  <div>
    <el-dialog
      v-if="$parent.type == 'dialog'"
      :title="$parent.title"
      :visible.sync="$parent.dialogVisible"
      top="6vh"
      :width="$parent.width"
      v-dialogDrag
      :close-on-click-modal="$parent.closeOnClickModal"
      :close-on-press-escape="$parent.closeOnPressEscape"
      :show-close="!$parent.submitting && $parent.showClose"
      @opened="$parent.opened"
      @close="$parent.close"
      @closed="$parent.closed"
      :modal-append-to-body="false"
      :append-to-body="$parent.appendToBody"
      :fullscreen="$parent.fullscreen"
      class="ypl-dialog"
      :class="{ 'ypl-dialog-fullscreen': $parent.fullscreen ? true : false, 'ypl-dialog-view': $parent.isView ? true : false }"
    >
      <div slot="title" class="dialog_title">
        <span class="el-dialog__title">{{ $parent.title }}</span>
        <i :class="$parent.fullscreen ? 'el-icon-news' : 'el-icon-full-screen'" @click="fullscreenFun" />
      </div>
      <div v-if="$parent.dialogVisible" v-yplLoading="$parent.loading">
        <slot />
      </div>
      <div v-if="showButton || this.$slots.buttons" slot="footer" class="dialog-footer">
        <div v-if="!this.$slots.buttons">
          <el-button v-if="!hideSubmit" type="primary" :loading="$parent.submitting" :disabled="$parent.loading" @click="$parent.submitForm">{{ submitText }}</el-button>
          <el-button :disabled="$parent.submitting" @click="$parent.dialogVisible = false">{{ cancelText }}</el-button>
        </div>
        <slot name="buttons" />
      </div>
    </el-dialog>
    <div class="page" v-if="$parent.type == 'page'">
      <div class="page_title">
        <span class="page_title_text">{{ $parent.title }}</span>
        <el-button type="text" @click="$parent.close"><i class="el-icon-close"></i></el-button>
      </div>
      <div class="page_body">
        <slot />
      </div>
      <div v-if="showButton" class="page_footer">
        <div v-if="!this.$slots.buttons">
          <el-button v-if="!hideSubmit" type="primary" :loading="$parent.submitting" :disabled="$parent.loading" @click="$parent.submitForm">{{ submitText }}</el-button>
          <el-button :disabled="$parent.submitting" @click="$parent.close">{{ cancelText }}</el-button>
        </div>
        <slot name="buttons" />
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: 'YplDialog',
  props: {
    showButton: {
      type: Boolean,
      default: true
    },
    hideSubmit: {
      type: Boolean,
      default: false
    },
    submitText: {
      type: String,
      default: '确 定'
    },
    cancelText: {
      type: String,
      default: '取 消'
    }
  },
  methods: {
    fullscreenFun() {
      this.$parent.fullscreen = !this.$parent.fullscreen
      if (this.$parent.$refs['ypl-table']) {
        this.$nextTick(() => {
          this.$parent.$refs['ypl-table'].resetTableHeight({ fullscreen: this.$parent.fullscreen })
        })
      }
    }
  }
}
</script>
<style lang="scss" scoped>
.ypl-dialog {
  ::v-deep .el-dialog__header {
    .dialog_title {
      display: flex;
      justify-content: space-between;
      i {
        padding-right: 20px;
        &:hover {
          cursor: pointer;
        }
      }
    }
    &::after {
      content: '';
      height: 1px;
      background-color: #dcdfe6;
      width: 100%;
      display: block;
      position: relative;
      top: 10px;
    }
  }
  &.ypl-dialog-fullscreen {
    ::v-deep .el-dialog__body {
      max-height: calc(100vh - 125px);
    }
    &.ypl-dialog-view {
      ::v-deep .el-dialog__body {
        max-height: calc(100vh - 55px);
      }
    }
  }
  ::v-deep .el-dialog__body {
    max-height: calc(94vh - 175px);
    overflow-y: auto;
    padding-top: 15px;
  }
}
.page {
  margin: 0 -20px; //抵掉页面自带左右20padding
  .page_title {
    margin: 0 20px;
    display: flex;
    justify-content: space-between;
    align-items: center;
    font-size: 18px;
    border-bottom: 1px solid #dcdfe6;
    .el-icon-close {
      font-size: 18px;
      color: #909399;
    }
  }
  .page_body {
    padding: 15px 20px 30px;
    overflow-y: auto;
    overflow-x: hidden;
    max-height: calc(100vh - 87px - 45px - 70px - 10px);
  }
  .page_footer {
    padding: 10px 20px 20px;
    text-align: right;
  }
}
</style>
