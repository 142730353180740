import Vue from 'vue'
import Vuex from 'vuex'
import { getUserInfo, login, logout, wxlogin } from '@/api/login'
import { getAllList } from '@/api/sys/sysData'
import { getAllUserList } from '@/api/sys/sysUser'
import { setToken, removeToken } from '@/utils/auth'
import router from '@/router'
import { getBrowserInfo } from '@/utils/others.js'

Vue.use(Vuex)

export default new Vuex.Store({
  state: {
    userOption: {},
    menuList: [],
    // menuList: [
    //   {
    //     menuUrl: '/inx/inxIndex',
    //     menuName: '首页',
    //     icon: 'icon-shouye'
    //   },
    //   {
    //     menuUrl: '/sys',
    //     menuName: '基础数据',
    //     icon: 'icon-jichu',
    //     childList: [
    //       {
    //         menuUrl: '/sys/sysUser',
    //         menuName: '用户管理',
    //         icon: 'icon-yonghu'
    //       },
    //       {
    //         menuUrl: '/sys/sysRole',
    //         menuName: '角色管理',
    //         icon: 'icon-jiaose'
    //       },
    //       {
    //         menuUrl: '/sys/sysCompany',
    //         menuName: '公司管理',
    //         icon: 'icon-gongsi'
    //       },
    //       {
    //         menuUrl: '/sys/sysDepartment',
    //         menuName: '部门管理',
    //         icon: 'icon-bumen'
    //       },
    //       {
    //         menuUrl: '/sys/sysNotice',
    //         menuName: '通知管理',
    //         icon: 'icon-tongzhi'
    //       },
    //       {
    //         menuUrl: '/sys/sysData',
    //         menuName: '数据字典',
    //         icon: 'icon-zidian'
    //       }
    //     ]
    //   },
    //   {
    //     menuUrl: '/cus',
    //     menuName: '客户关系',
    //     icon: 'icon-kehuguanxiguanli',
    //     childList: [
    //       {
    //         menuUrl: '/cus/cusCustomer',
    //         menuName: '客户管理',
    //         icon: 'icon-kehu'
    //       },
    //       {
    //         menuUrl: '/cus/cusOpenPool',
    //         menuName: '公海池',
    //         icon: 'icon-gonghaichi'
    //       },
    //       {
    //         menuUrl: '/cus/cusContacts',
    //         menuName: '联系人管理',
    //         icon: 'icon-lianxiren'
    //       },
    //       {
    //         menuUrl: '/cus/cusActivity',
    //         menuName: '活动管理',
    //         icon: 'icon-huodongguanli'
    //       }
    //     ]
    //   },
    //   {
    //     menuUrl: '/tra',
    //     menuName: '事务处理',
    //     icon: 'icon-shiwu',
    //     childList: [
    //       {
    //         menuUrl: '/tra/traBusiness',
    //         menuName: '商机管理',
    //         icon: 'icon-shangji'
    //       },
    //       {
    //         menuUrl: '/tra/traEvent',
    //         menuName: '事件管理',
    //         icon: 'icon-shijian'
    //       },
    //       {
    //         menuUrl: '/tra/traCalendar',
    //         menuName: '日历管理',
    //         icon: 'icon-rili'
    //       },
    //       {
    //         menuUrl: '/tra/traQuota',
    //         menuName: '配额管理',
    //         icon: 'icon-peie'
    //       }
    //     ]
    //   },
    //   {
    //     menuUrl: '/ser',
    //     menuName: '业务数据',
    //     icon: 'icon-yewu',
    //     childList: [
    //       {
    //         menuUrl: '/ser/serWaybill',
    //         menuName: '运单数据',
    //         icon: 'icon-jianhuo'
    //       }
    //     ]
    //   },
    //   {
    //     menuUrl: '/cc',
    //     menuName: 'Commit Call',
    //     icon: 'icon-tongji',
    //     childList: [
    //       {
    //         menuUrl: '/cc/ccPersonalCommitCall',
    //         menuName: '个人Commit Call',
    //         icon: 'icon-geren'
    //       },
    //       {
    //         menuUrl: '/cc/ccDepartmentCommitCall',
    //         menuName: '团队Commit Call',
    //         icon: 'icon-tuandui'
    //       },
    //       {
    //         menuUrl: '/cc/ccCompanyCommitCall',
    //         menuName: '公司Commit Call',
    //         icon: 'icon-gongsi'
    //       }
    //     ]
    //   }
    // ], //菜单
    permissionList: [], //按钮权限
    menuOpend: true, //菜单开启缩放
    isDark: false, //暗黑模式
    visitedRoutesList: [], //tab展示已访问的路由
    windowSize: {
      width: document.documentElement.clientWidth,
      height: document.documentElement.clientHeight
    },
    //数据字典
    getsysData: false,
    sysDataTimeout: null,
    sysData: null,
    getsysDataing: false,
    //用户列表数据
    getUserData: false,
    userDataTimeout: null,
    userData: null,
    getuserDataing: false,
    //用户操作日志埋点信息
    operationInfor: {
      userId: '',
      browser: getBrowserInfo() //浏览器
      // ip: localStorage.getItem('ip')
    }
  },
  getters: {},
  mutations: {
    setUserOption(state, option) {
      state.userOption = option
      state.operationInfor.userId = option.userId
    },
    setMenuList(state, menuList) {
      state.menuList = menuList
    },
    setPermissionList(state, permissionList) {
      state.permissionList = permissionList
    },
    setMenuOpend(state) {
      state.menuOpend = !state.menuOpend
    },
    setDark(state, status) {
      // console.log('status', status)
      if (status !== undefined) {
        state.isDark = status
      } else {
        state.isDark = !state.isDark
      }
    },
    setWindowSize(state, { width, height }) {
      state.windowSize.width = width
      state.windowSize.height = height
    },
    //操作visitedRoutesList
    addVisitedRoutes(state, item) {
      if (state.visitedRoutesList.every(one => one.path != item.path)) {
        state.visitedRoutesList.push(item)
      }
    },
    delVisitedRoutes(state, item) {
      state.visitedRoutesList = state.visitedRoutesList.filter(one => one.path != item.path)
    },
    clearVisitedRoutes(state) {
      state.visitedRoutesList = []
    },
    //数据字典
    setSysData(state, list) {
      state.sysData = new Map(Object.entries(list))
      if (state.sysDataTimeout) {
        clearTimeout(state.sysDataTimeout)
        state.sysDataTimeout = null
      }
      state.sysDataTimeout = setTimeout(() => {
        state.sysData = null
      }, 30 * 60 * 1000)
    },
    clearSysData(state) {
      if (state.sysDataTimeout) {
        clearTimeout(state.sysDataTimeout)
        state.sysDataTimeout = null
      }
      state.sysData = null
      state.getsysData = false
    },
    //用户列表数据
    setUserData(state, list) {
      state.userData = new Map(list)
      console.log(state.userData)
      if (state.userDataTimeout) {
        clearTimeout(state.userDataTimeout)
        state.userDataTimeout = null
      }
      state.userDataTimeout = setTimeout(() => {
        state.userData = null
      }, 30 * 60 * 1000)
    },
    clearUserData(state) {
      if (state.userDataTimeout) {
        clearTimeout(state.userDataTimeout)
        state.userDataTimeout = null
      }
      state.userData = null
      state.getUserData = false
    }
  },
  actions: {
    GetUserInfo({ commit }) {
      return new Promise((resolve, reject) => {
        getUserInfo()
          .then(res => {
            // console.log(res)
            let menuList = res.data.menuList
            let routes = router.options.routes
            function setIcon(list) {
              list?.forEach(one => {
                getRouterIcon(one, routes)
                if (one.childList && one.childList.length) {
                  setIcon(one.childList)
                }
              })
            }
            function getRouterIcon(obj, arr) {
              arr.forEach(one => {
                if (one.meta && one.meta.path && one.meta.path == obj.menuUrl) {
                  obj.icon = one.meta.icon
                }
                if (!obj.icon && one.children) {
                  getRouterIcon(obj, one.children)
                }
              })
            }
            setIcon(menuList)
            console.log(menuList)
            commit('setMenuList', menuList)
            commit('setPermissionList', res.data.permissionList)
            commit('setUserOption', res.data)
            resolve(res)
          })
          .catch(error => {
            reject(error)
          })
      })
    },
    // 登录
    Login({ commit }, userInfo) {
      return new Promise((resolve, reject) => {
        login(userInfo)
          .then(res => {
            console.info('--------------store user.js res=' + res)
            console.info('--------------store user.js res.token=' + res.data)
            setToken(res.data.token)
            resolve()
          })
          .catch(error => {
            reject(error)
          })
      })
    },
    // 企业微信登录
    wxLogin({ commit }, obj) {
      return new Promise((resolve, reject) => {
        wxlogin(obj)
          .then(res => {
            setToken(res.data.token)
            resolve()
          })
          .catch(error => {
            reject(error)
          })
      })
    },
    LogOut({ commit }) {
      return new Promise((resolve, reject) => {
        logout()
          .then(res => {
            commit('clearSysData')
            commit('clearUserData')
            commit('clearVisitedRoutes')
            removeToken()
            resolve(res)
          })
          .catch(error => {
            reject(error)
          })
      })
    },
    //操作tab
    DelVisitedRoutesList({ commit }, item) {
      return new Promise((resolve, reject) => {
        commit('delVisitedRoutes', item)
        resolve()
      }).catch(error => {
        reject(error)
      })
    },
    //数据字典
    GetsysData({ commit, state }) {
      return new Promise((resolve, reject) => {
        getAllList().then(res => {
          state.getsysData = true
          state.getsysDataing = false
          if (res.data) {
            commit('setSysData', res.data)
          }
          resolve()
        })
      })
    },
    GetsysDataByType({ dispatch, state }, type) {
      return new Promise((resolve, reject) => {
        if (state.sysData) {
          resolve(state.sysData.get(type)?.filter(one => one.status == 'ENABLE'))
        } else {
          if (state.getsysDataing) {
            let int = setInterval(() => {
              if (!state.getsysDataing) {
                clearInterval(int)
                resolve(state.sysData.get(type)?.filter(one => one.status == 'ENABLE'))
              }
            }, 100)
          } else {
            state.getsysDataing = true
            dispatch('GetsysData').then(() => {
              resolve(state.sysData.get(type)?.filter(one => one.status == 'ENABLE'))
            })
          }
        }
      })
    },
    //用户列表数据
    GetUserData({ commit, state }) {
      return new Promise((resolve, reject) => {
        getAllUserList().then(res => {
          state.getUserData = true
          state.getuserDataing = false
          if (res.data) {
            let newList = res.data.map(one => {
              return [one.id, one]
            })
            commit('setUserData', newList)
          }
          resolve()
        })
      })
    },
    GetUserDataById({ dispatch, state }, id) {
      return new Promise((resolve, reject) => {
        if (state.userData) {
          resolve(state.userData.get(id))
        } else {
          if (state.getuserDataing) {
            let int = setInterval(() => {
              if (!state.getuserDataing) {
                clearInterval(int)
                resolve(state.userData.get(id))
              }
            }, 100)
          } else {
            state.getuserDataing = true
            dispatch('GetUserData').then(() => {
              resolve(state.userData.get(id))
            })
          }
        }
      })
    }
  },
  modules: {}
})
