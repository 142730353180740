<template>
  <div class="menu_box" :class="isCollapse ? 'hide_menu' : ''">
    <el-scrollbar class="menu_scrollbar">
      <el-menu :default-active="$route.path" :collapse="isCollapse" text-color="#000000" :unique-opened="true" active-text-color="#409EFF" background-color="#FFFFFF" :collapse-transition="false" mode="vertical">
        <menu-item v-for="(item, index) in menuList" :key="index" :routeItem="item" />
      </el-menu>
    </el-scrollbar>
  </div>
</template>
<script>
import { mapState } from 'vuex'
import menuItem from './menuItem'
export default {
  data() {
    return {}
  },
  computed: {
    ...mapState(['menuOpend', 'menuList']),
    isCollapse() {
      return !this.menuOpend
    }
  },
  components: {
    menuItem
  }
}
</script>
<style lang="scss" scoped>
.menu_box {
  transition: width 0.28s;
  width: 200px;
  background-color: #ffffff;
  height: 100%;
  position: fixed;
  font-size: 0px;
  top: 0;
  bottom: 0;
  left: 0;
  z-index: 1001;
  box-shadow: 2px 0 6px rgba(0, 21, 41, 0.35);
  &.hide_menu {
    width: 54px;
  }
  .menu_scrollbar {
    height: 100%;
  }
}
</style>
<style lang="scss">
.menu_box {
  &.hide_menu {
    .el-submenu__icon-arrow {
      display: none;
    }
    .el-menu--collapse {
      width: 54px;
      .el-tooltip {
        padding: 0px 15px !important;
      }
      .el-submenu__title {
        padding-left: 15px !important;
      }
    }
    .el-submenu__title {
      > span {
        display: none;
      }
    }
  }
  .el-menu {
    border: none;
  }
  .el-scrollbar__wrap {
    overflow-x: hidden;
  }
}
</style>
