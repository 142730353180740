<template>
  <ypl-dialog>
    <ypl-dialog-form ref="ypl-dialog-form">
      <el-row :gutter="gutter">
        <el-col :span="12" v-if="!updatePasswordFlag">
          <el-form-item label="旧密码" prop="oldPassword">
            <ypl-input v-model="form.oldPassword" placeholder="请输入旧密码" clearable show-password autocomplete="new-password" />
          </el-form-item>
        </el-col>
        <el-col :span="12">
          <el-form-item label="新密码" prop="password">
            <ypl-input v-model="form.password" placeholder="请输入新密码" clearable show-password autocomplete="new-password" />
          </el-form-item>
        </el-col>
        <el-col :span="12">
          <el-form-item label="重复新密码" prop="againPassword">
            <ypl-input v-model="form.againPassword" placeholder="请再次输入新密码" clearable show-password autocomplete="new-password" />
          </el-form-item>
        </el-col>
      </el-row>
    </ypl-dialog-form>
    <div slot="buttons" v-if="updatePasswordFlag">
      <el-button type="primary" :loading="submitting" :disabled="loading" @click="submitForm">确 定</el-button>
      <el-button @click="logout">退 出</el-button>
    </div>
  </ypl-dialog>
</template>

<script>
import dialogMixins from '@/mixins/dialog'
import { updatePassword } from '@/api/sys/sysUser'
export default {
  mixins: [dialogMixins],
  data() {
    let checkPassword = (rule, value, callback) => {
      if (value === '') {
        callback(new Error('请再次输入新密码'))
      } else {
        if (this.form.password === this.form.againPassword) {
          callback()
        } else {
          callback(new Error('两次密码不一致'))
        }
      }
    }
    return {
      title: '修改密码',
      updatePasswordFlag: false,
      rules: {
        oldPassword: [{ required: true, message: '请输入旧密码', trigger: 'blur' }],
        password: [{ required: true, message: '请输入新密码', trigger: 'blur' }],
        againPassword: [
          { required: true, message: '请再次输入新密码', trigger: 'blur' },
          { validator: checkPassword, trigger: 'blur' }
        ]
      }
    }
  },
  methods: {
    open(option) {
      //打开弹窗
      this.dialogVisible = true
      if (option) {
        this.updatePasswordFlag = option.updatePasswordFlag
        this.showClose = !option.updatePasswordFlag
        this.title = '为确保您账户的安全，请修改密码'
      }
    },
    submit() {
      return updatePassword(this.form)
    },
    afterSubmit(response) {
      this.$message({
        message: '保存成功,请重新登录',
        type: 'success'
      })
      setTimeout(() => {
        this.$store.dispatch('LogOut').then(() => {
          this.$router.push('/login')
        })
      }, 500)
    },
    logout() {
      this.$store.dispatch('LogOut').then(() => {
        this.$router.push('/login')
      })
    }
  }
}
</script>
