<template>
  <el-time-picker is-range v-model="value" value-format="HH:mm:ss" :range-separator="rangeSeparator" :start-placeholder="startPlaceholder" :end-placeholder="endPlaceholder" :picker-options="pickerOptions" v-bind="$attrs" v-on="$listeners" />
</template>

<script>
export default {
  name: 'YplDatetimerangePicker',
  props: {
    start: {
      required: true
    },
    end: {
      required: true
    },
    startPlaceholder: {
      type: String,
      default: '开始时间'
    },
    endPlaceholder: {
      type: String,
      default: '结束时间'
    },
    rangeSeparator: {
      type: String,
      default: '至'
    },
    pickerOptions: {
      type: Object,
      default: () => {
        return {}
      }
    }
  },
  computed: {
    value: {
      get() {
        return [this.start || '', this.end || '']
      },
      set(val) {
        this.$emit('update:start', val ? val[0] : '')
        this.$emit('update:end', val ? val[1] : '')
      }
    }
  }
}
</script>
